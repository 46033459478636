import { useParams } from 'react-router-dom';
import ChildHeader from '../../../../components/ui/ChildHeader';
import { useQuery } from '../../../../hooks/UseQuery';
import RIPartListByVendor from './RiPartListByVendor';

const SelectRiPart = () => {
  const { vendorId } = useParams();
  const documentType = useQuery('documentType') || '';

  return (
    <div>
      <ChildHeader text="RI PART LIST"></ChildHeader>
      <RIPartListByVendor
        type="redirect"
        documentType={documentType}
        link={`/common/supplierDocumentNew/${vendorId}`}
      />
    </div>
  );
};

export default SelectRiPart;
