import { TableCell } from '@mui/material';
import axios from 'axios';
import { useEffect, useState } from 'react';

const TableCellStatus = ({ vendorId }: { vendorId: string | undefined }) => {
  const [data, setData] = useState<any>({});
  useEffect(() => {
    if (vendorId) {
      axios
        .get(`/api/supplierDocument/isSupplierDocumentExits/${vendorId}`)
        .then((res) => {
          if (res.data) {
            setData(res.data);
          }
        })
        .catch((err) => console.log(err));
    }
  }, [vendorId]);
  return (
    <>
      <TableCell align="center">
        <span
          style={{
            background: data.countCp === 0 ? 'red' : 'green',
            padding: '10px 14px',
            borderRadius: '50%',
            color: 'white',
          }}
        >
          {data.countSupplier}
        </span>
      </TableCell>
    </>
  );
};

export default TableCellStatus;
