import { VendirList } from '../pages/RIDeparment/MasterListOfVendor';
import {
  CreateInpsectionStandard,
  InspectionStandardList,
  RIDeparmentHome,
  RiDocumentUpload,
  RIPart,
  SupplierDocument,
  SupplierDocumentHome,
  VendorListNew,
  PipeList,
  PipeHome,
  CreatePipeInpsectionStandard,
  PipeInspectionStandardList,
  PipeInspectionReportList,
  PipeInspectionReport,
  LuxLevelObsSheetList,
  CreateLuxLevelObsSheet,
  SupplierDocumentNew,
  SupplierDocumentHomeNew,
} from '../pages/RIDeparment';
import {
  CalibrationTeamHome,
  InstrumentMsaPlanTiles,
  InstrumentMsaPlanList,
  InstrumentMsaPlanCreate,
  InstrumentMsaPlanChecklistList,
  MasterListofInstrumentMsaPlan,
  GaugeMsaPlanTiles,
  GaugeMsaPlanList,
  GaugeMsaPlanCreate,
  GaugeMsaPlanChecklistList,
  MasterListofGaugeMsaPlan,
  ScopeCalibrationAgencyUpload,
  InstrumentCalibrationTiles,
  InstrumentCalibrationPlanList,
  InstrumentCalibrationPlanCreate,
  InstrumentCalibrationChecklistList,
} from '../pages/calibrationTeam';

// inspection report imperial
import {
  InspectionReport,
  InspectionReportList,
} from '../pages/inspectionReportImperial';
import { DrawingnManagement } from '../pages/drawingManagement';
import {
  CreateProductionLogBook,
  FirstFloorTiles,
  GroundFloorTiles,
  ProductionLogBookHome,
  ProductionLogBookTableList,
} from '../pages/productionLogBook';
import {
  CustomerList,
  CustomerStandardList,
} from '../pages/customerStandardModule';

import {
  RejectionNoteHome,
  CreateRejectionNote,
  RejectionNoteList,
  RejectionNoteGroundFloorList,
  CreateRejectionGroundFloorNote,
} from '../pages/rejectionNote';
import { SupplierComplaintList } from '../pages/supplierComplaint';

// start of quality department
import {
  PirListHome,
  QualityDeparmentHome,
  QualityPirCreate,
  DieselMonitoringSheetList,
  DieselMonitoringSheetCreate,
  WaterChangeFrequency,
  AirDecayChecksheet,
  AirUnderWaterChecksheet,
  UltraSonicWaterChangeCheckSheet,
  FlushingOilChangeCheckSheet,
  ProcessWiseWiUpload,
  PallMachineCheckList,
  PallMachineCheckListCreate,
  QualityDefects,
  AmgRejectionNote,
  CmmLogBookList,
  AmgRejectionNoteDashboardTabs,
} from '../pages/qualityDepartment';
import {
  SetupApprovalList,
  CreateSetupApproval,
} from '../pages/qualityDepartment/setUpApproval';
import {
  HourlyMonitoringList,
  CreateHourlyMonitoring,
} from '../pages/qualityDepartment/hourlyMonitoringSheet';
import {
  DailyRejMonitoringList,
  CreateDailyRejMonitoring,
} from '../pages/qualityDepartment/dailyRejMonitoringSheet';
import {
  AbnormalSitChkShtList,
  CreateAbnormalSitChkSht,
} from '../pages/qualityDepartment/abnormalSitCheckSheet';
import {
  DailyBinCleaningChkShtList,
  CreateDailyBinCleaningChkSht,
} from '../pages/qualityDepartment/dailyBinCleaningCheckSheet';
import {
  PokaYokeChkShtList,
  CreatePokaYokeChkSht,
} from '../pages/qualityDepartment/pokeYokeCheckSheet';

import PartListOfQtyDept from '../pages/qualityDepartment/partList/List';
import QualityDepartmentUpload from '../pages/qualityDepartment/qualityDepartmentUpload/QualityDepartmentUpload';
import RejectionNoteDashboardTabs from '../pages/qualityDepartment/rejectionNoteDashboard/RejectionAnalysisDashboardTabs';

// spc starting for iai in qlty dept
import PartListForSPC from '../pages/spc/PartListForSPC';
import SpcTiles from '../pages/spc/Tiles';
import SpcList from '../pages/spc/List';
import CreateEditSpc from '../pages/spc/CreateEdit';
import UnderConstructionPage from '../underconstruction-page';
import {
  CreateTitrationChkSht,
  TitrationChkShtList,
} from '../pages/qualityDepartment/titrationAndFlushingChecksheet';
import {
  CreatePPEChkSht,
  PPEChkShtList,
} from '../pages/qualityDepartment/ppeChkSht';
// spc end of spc qlty dept
import ToolsList from '../pages/masterlisofTools/List';
import ToolsHistory from '../pages/masterlisofTools/toolsHistory/CreateHistory';
import { MilliporeCreate, MilliporeList } from '../pages/millipore';
import { AirbornCreate, AirbornList } from '../pages/Airborne';
import {
  ChecklistList,
  InstrumentTiles,
  PlanCreate,
  PlanList,
} from '../pages/instrumentPlan';
import { InstrumentMasterList } from '../pages/masterlistofInstrument';
import {
  CreateLeicaRoom,
  LeicaRoomList,
} from '../pages/qualityDepartment/leicaRoom';
import {
  PdiDepartmentHome,
  PDIStandardList,
  CreatePDIStandard,
} from '../pages/pdiDepartment';
import {
  FixturesPlanTiles,
  FixturesPlanList,
  FixturesPlanCreate,
  FixturesPlanChecklistList,
} from '../pages/fixturesplan';

import {
  AttributeGaugesTiles,
  AttributeGaugesPlanList,
  AttributeGaugesPlanCreate,
  AttributeGaugesChecklistList,
  MasterListofGauges,
} from '../pages/attributeGaugesPlan';
import {
  AppearancePlanTiles,
  AppearancePlanPlanList,
  AppearancePlanPlanCreate,
  AppearancePlanChecklistList,
  MasterListofAppearancePlan,
} from '../pages/appearancePlan';
import { CriticalSparePartsList } from '../pages/masterListOfCriticalSpareParts';
import { PfmeaCreate, PfmeaList } from '../pages/pfmeaManagement';
//import { MaintenanceLogTiles } from '../pages/clause10Page';
import MaintenanceLogTiles from '../pages/clause10Page/maintenanceLog/MaintenanceLogTiles';
import { BreakDownFinalList } from '../pages/clause10Page/maintenanceLog/breakDownLogBook'; //pdirIAI/pdirFinalList';
import {
  MtbfList,
  MtbfCreate,
} from '../pages/clause10Page/maintenanceLog/MTBF';
import { KRAList, KRACreate, KRATiles } from '../pages/kra';
import {
  MttrList,
  MttrCreate,
} from '../pages/clause10Page/maintenanceLog/MTTR';
import {
  StoreManagementHome,
  StorePartCreate,
  StoreSetupCreate,
  StoreReceiveRegisterList,
  StoreAssignRegisterList,
  StoreIssueRegisterList,
  StoreOverviewCreate,
  StoreBinCreationList,
  ConsumableList,
} from '../pages/storeManagement';
import {
  OldFvpPlanTiles,
  OldFvpPlanList,
  OldFvpPlanCreate,
  OldFvpPlanChecklistList,
  MasterListofOldFvpPlan,
} from '../pages/oldFvpplan';
import {
  RawMaterialPlanTiles,
  RawMaterialPlanPlanList,
  RawMaterialPlanPlanCreate,
} from '../pages/rawMaterialTestPlan';

import {
  RubberoringPartsTiles,
  RubberoringPartsPlanList,
  RubberoringPartsPlanCreate,
  RubberoringPartsChecklistList,
  MasterListofRubberoringParts,
} from '../pages/rmTestPlanOringparts';

import {
  SSTPlanTiles,
  SSTPlanPlanList,
  SSTPlanPlanCreate,
  SSTPlanChecklistList,
  MasterListofSSTPlan,
} from '../pages/SSTPlan';

import {
  TubesTiles,
  TubesPlanList,
  TubesPlanCreate,
  TubesChecklistList,
  MasterListofTubes,
} from '../pages/TubesPlan';
import {
  BOPPlanTiles,
  BOPPlanPlanList,
  BOPPlanPlanCreate,
  BOPPlanChecklistList,
  MasterListofBOPPlan,
} from '../pages/BOPPlan';
import {
  DockAuditPlanChecklistCreate,
  DockAuditPlanChecklistList,
  DockAuditPlanPlanCreate,
  DockAuditPlanPlanList,
  DockAuditPlanTiles,
} from '../pages/dockAuditPlan';
import {
  Clause5,
  DesignationList,
  EmployeeList,
  DepartmentList,
  TrainingTniHome,
  TNITiles,
  TniTopicList,
  CreateEditTopicPaper,
  QuickViewTopicPaper,
  FacultyList,
  JobDescHome,
  MaterListTniCategory,
  EmployeeJD,
  TrainingCalenderTiles,
  TniTrainingPlanCreate,
  TniTrainingPlanList,
  TniTrainingPlanChecklistList,
  TniTrainingPlanTiles,
  TniCsrPlanTiles,
  TniCsrPlanCreate,
  TniCsrPlanList,
  TniCsrPlanChecklistList,
  CompetenceMatrixEmployee,
  TniPlanCreate,
  TniPlanList,
  MaterListSkillCategory,
  SkillEvaluationHome,
  CreateEditTopicSkillPaper,
  ViewTopicSkillPaper,
  SkillMatrixList,
  SkillMatrixCreate,
} from '../pages/clause5';
import { Clause18 } from '../pages/clause18Page';
import {
  Clause1,
  CreateMatrix,
  CreateNpdTimePlan,
  LessonLearnList,
  MasterListOfProject,
  MatrixList,
  NpdPartList,
  TimePlanList,
} from '../pages/clause1';

import { Management5S } from '../pages/management5S';

import { ClauseUploadList } from '../pages/clauseUpload';
import {
  ProcessAuditPlanChecklistCreate,
  ProcessAuditPlanChecklistList,
  ProcessAuditPlanCreate,
  ProcessAuditPlanList,
  ProcessAuditTiles,
  MasterListOfProcessAudit,
} from '../pages/processAuditPlan';
import {
  ProductAuditPlanTiles,
  ProductAuditPlanPlanList,
  ProductAuditPlanPlanCreate,
  ProductAuditPlanChecklistList,
  ProductAuditPlanChecklistCreate,
  MasterListofProductAuditPlan,
} from '../pages/productionAuditPlan';

import {
  LayeredAuditPlanChecklistCreate,
  LayeredAuditPlanChecklistList,
  LayeredAuditPlanCreate,
  LayeredAuditPlanList,
  LayeredAuditPlanTiles,
} from '../pages/layeredAuditPlan';

import { JDCreate, JDList } from '../pages/clause5/jobDesc/jd';

import { SafetyCommittee } from '../pages/safetyCommittee';
import { MushinHelpDesk } from '../pages/Mushin_Help_Desk';

import { NotificationList } from '../pages/notifications';
import { FirstAidBoxList } from '../pages/firstAidBox';
import { ListOfFireExtinguisher } from '../pages/listOfFireExtinguisher';
import {
  SupplierAuditTiles,
  SupplierAuditPlanList,
  SupplierAuditPlanCreate,
  SupplierAuditChecklistList,
  SupplierAuditChecklistCreate,
  SupplierOverallRatingList,
  SupplierOverallRatingCreate,
  SupplierSpcPlanTiles,
  SupplierSpcPlanList,
  SupplierSpcPlanCreate,
  SupplierSpcPlanChecklistList,
  SupplierLayoutPlanTiles,
  SupplierLayoutPlanList,
  SupplierLayoutPlanCreate,
  SupplierLayoutPlanChecklistList,
  SupplierPpmList,
  SupplierPpmCreate,
  SupplierPpmDashboardTabs,
  MaterialTestPlanHoseTiles,
  MaterialTestPlanHoseList,
  MaterialTestPlanHoseCreate,
  MaterialTestPlanHoseChecklistList,
  MaterialTestPlanOringAndGrommetTiles,
  MaterialTestPlanOringAndGrommetList,
  MaterialTestPlanOringAndGrommetCreate,
  MaterialTestPlanOringAndGrommetChecklistList,
  MaterialTestPlanOtherTiles,
  MaterialTestPlanOtherList,
  MaterialTestPlanOtherCreate,
  MaterialTestPlanOtherChecklistList,
} from '../pages/RIDeparment';
import { MilliPoreRunChart } from '../pages/milliporeRunChart';
import {
  PartLayoutPlanTiles,
  PartLayoutPlanList,
  PartLayoutPlanCreate,
  PartLayoutPlanChecklistList,
} from '../pages/partLayoutPlan';
import { ContingencyPlan, ListOfProcedures } from '../pages/clause4Page';
import {
  CustomerSatisfactionSurveyList,
  InHouseComplaintRegisterList,
  PastTroubleDatabaseList,
} from '../pages/clause8Page';
import {
  InternalQualityAuditPlanTiles,
  InternalQualityAuditPlanList,
  InternalQualityAuditPlanCreate,
  InternalQualityAuditPlanChecklistList,
  InternalQualityAuditPlanChecklistCreate,
  VdaProcessAuditPlanTiles,
  VdaProcessAuditPlanList,
  VdaProcessAuditPlanCreate,
  VdaProcessAuditPlanChecklistList,
  VdaProcessAuditPlanChecklistCreate,
  MasterListOfProcessVda,
  ListOfProceduresMRMUploads,
} from '../pages/clause6Page';
import {
  CustomerSatisfactionSurveyPlanTiles,
  CustomerSatisfactionSurveyPlanList,
  CustomerSatisfactionSurveyPlanCreate,
  CustomerSatisfactionSurveyPlanChecklistList,
  CustomerSatisfactionSurveyPlanChecklistCreate,
} from '../pages/clause8Page/customerSatisfactionSurveyPlan';
import {
  ProductionDeptHome,
  OeeCalculationList,
  OeeCalculationCreate,
} from '../pages/productionDept';
import LeicaHome from '../pages/qualityDepartment/LeicaHome';
import { KaizenSubmissionList } from '../pages/kaizen';
import { ListOfSlideShow } from '../pages/listOfSlideShow';
import SlideShow from '../pages/listOfSlideShow/SlideShow';
import {
  AmgReworkList,
  CreateAmgRework,
  CreateJdRework,
  CreateTubeRework,
  JdReworkList,
  ReworkRegisterHome,
  TubeReworkList,
} from '../pages/reworkRegister';
import ApprovalList from '../pages/pendingForApproval/ApprovalList';
import { CreateForMSummary, SummaryTableList } from '../pages/4m';
import {
  CreateSSTPlatingInpsectionStandard,
  SSTPlatingHome,
  SSTPlatingInspectionReport,
  SSTPlatingInspectionReportList,
  SSTPlatingInspectionStandardList,
  SSTPlatingList,
} from '../pages/RIDeparment/sstPlatingModule';
import {
  CompetencyMatrixCreate,
  CompetencyMatrixList,
} from '../pages/clause5/jobDesc/completencyMatrix';
import SelectRiPart from '../pages/RIDeparment/supplierDocumentNew/components/SelectRiPart';
import VendorList from '../pages/RIDeparment/supplierDocument/VendorList';
interface routes {
  path: string;
  element: JSX.Element;
  errorElement?: string;
}
const productionDept = [
  {
    path: 'home/prductionDept',
    element: <ProductionDeptHome />,
  },
  {
    path: 'oeeCalculationList',
    element: <OeeCalculationList />,
  },
  {
    path: 'createOeeCalculation/:id?',
    element: <OeeCalculationCreate />,
  },
];
const clause6 = [
  // Internal Quality Audit Plan
  {
    path: 'internalQualityAuditPlanTiles',
    element: <InternalQualityAuditPlanTiles />,
  },
  {
    path: 'internalQualityAuditPlanlist',
    element: <InternalQualityAuditPlanList />,
  },
  {
    path: 'internalQualityAuditPlanCreate/:id',
    element: <InternalQualityAuditPlanCreate />,
  },

  {
    path: 'internalQualityAuditPlanChecklist',
    element: <InternalQualityAuditPlanChecklistList />,
  },
  {
    path: 'internalQualityAuditPlanChecklistCreate/:moduleId?',
    element: <InternalQualityAuditPlanChecklistCreate />,
  },
  // VDA 6.3 Process Audit Plan
  {
    path: 'vdaProcessAuditPlanTiles',
    element: <VdaProcessAuditPlanTiles />,
  },
  {
    path: 'vdaProcessAuditPlanlist',
    element: <VdaProcessAuditPlanList />,
  },
  {
    path: 'masterListOfProcessVda',
    element: <MasterListOfProcessVda />,
  },
  {
    path: 'vdaProcessAuditPlanCreate/:id',
    element: <VdaProcessAuditPlanCreate />,
  },
  {
    path: 'vdaProcessAuditPlanChecklist',
    element: <VdaProcessAuditPlanChecklistList />,
  },
  {
    path: 'vdaProcessAuditPlanChecklistCreate/:moduleId?',
    element: <VdaProcessAuditPlanChecklistCreate />,
  },
  {
    path: 'listOfProceduresMRMUploads',
    element: <ListOfProceduresMRMUploads />,
  },
];
const supplierAuditPlan = [
  {
    path: 'supplierAuditTiles',
    element: <SupplierAuditTiles />,
  },
  {
    path: 'supplierAuditPlanList',
    element: <SupplierAuditPlanList />,
  },
  {
    path: 'supplierAuditPlanCreate/:id',
    element: <SupplierAuditPlanCreate />,
  },
  {
    path: 'supplierAuditChecklistList',
    element: <SupplierAuditChecklistList />,
  },
  {
    path: 'supplierAuditChecklistCreate/:moduleId?',
    element: <SupplierAuditChecklistCreate />,
  },
];

const inspectionStandard = [
  {
    path: 'inspectionStandardList',
    element: <InspectionStandardList />,
  },
  {
    path: 'inspectionStandard/:inspectionStandardId',
    element: <CreateInpsectionStandard />,
  },
];
// inspection report imperial route
const inspectionReportRoute = [
  {
    path: 'inspectionReportList',
    element: <InspectionReportList />,
  },
  {
    path: 'inspectionReport/:partId/:inspectionReportId?',
    element: <InspectionReport />,
  },
];
//production log book
const productionLogBook = [
  {
    path: 'productionLogBookHome',
    element: <ProductionLogBookHome />,
  },
  {
    path: 'productionLogFirstFloorHome',
    element: <FirstFloorTiles />,
  },
  {
    path: 'productionLogGroundFloorHome',
    element: <GroundFloorTiles />,
  },
  {
    path: 'productionLogBookList',
    element: <ProductionLogBookTableList />,
  },
  {
    path: 'createProductionLogBook/:productionLogBookId?',
    element: <CreateProductionLogBook />,
  },
];
// Supplier Document
const supplierDocument = [
  {
    path: 'vendorDocumentListNew',
    element: <VendorListNew />,
  },
  {
    path: 'supplierDocumentHomeNew/:vendorId',
    element: <SupplierDocumentHomeNew />,
  },
  {
    path: 'riSelectPart/:vendorId',
    element: <SelectRiPart />,
  },
  {
    path: 'supplierDocumentNew/:vendorId/:riPartId',
    element: <SupplierDocumentNew />,
  },
  // old supplier document
  {
    path: 'vendorDocumentList',
    element: <VendorList />,
  },
  {
    path: 'supplierDocumentHome/:vendorId',
    element: <SupplierDocumentHome />,
  },
  {
    path: 'supplierDocument/:vendorId',
    element: <SupplierDocument />,
  },
];

// rejection note module
export const rejectionNoteRoute = [
  {
    path: 'rejectionNoteHome',
    element: <RejectionNoteHome />,
  },
  {
    path: 'firstFloorList',
    element: <RejectionNoteList />,
  },
  {
    path: 'createEditFirstFloor/:id?',
    element: <CreateRejectionNote />,
  },
  {
    path: 'groundFloorList',
    element: <RejectionNoteGroundFloorList />,
  },
  {
    path: 'createEditGroundFloor/:id?',
    element: <CreateRejectionGroundFloorNote />,
  },
];
// rework register module
const reworkRegisterRoute = [
  {
    path: 'reworkRegisterHome',
    element: <ReworkRegisterHome />,
  },
  {
    path: 'jdReworkList',
    element: <JdReworkList />,
  },
  {
    path: 'createJdRework/:id?',
    element: <CreateJdRework />,
  },
  {
    path: 'tubeReworkList',
    element: <TubeReworkList />,
  },
  {
    path: 'createTubeRework/:id?',
    element: <CreateTubeRework />,
  },
  {
    path: 'amgReworkList',
    element: <AmgReworkList />,
  },
  {
    path: 'createAmgRework/:id?',
    element: <CreateAmgRework />,
  },
];

//customer standard module
const customerStandardModule = [
  {
    path: 'customerList',
    element: <CustomerList />,
  },
  {
    path: 'customerStandardList/:customerId',
    element: <CustomerStandardList />,
  },
];
const clause8 = [
  {
    path: 'customerSatisfactionSurveyList',
    element: <CustomerSatisfactionSurveyList />,
  },
];
//Pfmea Management
const pfmeaManagement = [
  {
    path: 'pfmeaList',
    element: <PfmeaList />,
  },
  {
    path: 'editPfmea/:id',
    element: <PfmeaCreate />,
  },
];
//store Management
const storeManagement = [
  {
    path: 'storeManagement',
    element: <StoreManagementHome />,
  },
  {
    path: 'storeBinCreationList',
    element: <StoreBinCreationList />,
  },
  {
    path: 'storePartCreate',
    element: <StorePartCreate />,
  },
  {
    path: 'storeSetupCreate',
    element: <StoreSetupCreate />,
  },
  {
    path: 'storeReceiveRegisterList',
    element: <StoreReceiveRegisterList />,
  },
  {
    path: 'storeReceiveRegisterList',
    element: <StoreReceiveRegisterList />,
  },
  {
    path: 'storeAssignRegisterList',
    element: <StoreAssignRegisterList />,
  },
  {
    path: 'issueRegisterList',
    element: <StoreIssueRegisterList />,
  },
  {
    path: 'storeOverviewCreate',
    element: <StoreOverviewCreate />,
  },
  {
    path: 'store/consumable/list',
    element: <ConsumableList />,
  },
];

//Pir Report
const qualityDepartment = [
  {
    path: 'processWiseWiUpload',
    element: <ProcessWiseWiUpload />,
  },
  {
    path: 'qualitydepartment/dashboard',
    element: <RejectionNoteDashboardTabs />,
  },
  {
    path: 'qualityPirList',
    element: <PirListHome />,
  },
  {
    path: 'qualityDepartmentHome',
    element: <QualityDeparmentHome />,
  },
  {
    path: 'qualityPir/:partId/:pirId?',
    element: <QualityPirCreate />,
  },
  {
    path: 'qualityDepartmentSetUpApprovalList',
    element: <SetupApprovalList />,
  },
  {
    path: 'createQualityDepartmentSetUpApproval/:id?',
    element: <CreateSetupApproval />,
  },
  {
    path: 'qualityDepartmentHourlyMonitoringSheetList',
    element: <HourlyMonitoringList />,
  },
  {
    path: 'createQualityDepartmentHourlyMonitoringSheet/:id?',
    element: <CreateHourlyMonitoring />,
  },
  {
    path: 'qualityDepartmentDailyRejMonitoringSheetList',
    element: <DailyRejMonitoringList />,
  },
  {
    path: 'createQualityDepartmentDailyRejMonitoringSheet/:id?',
    element: <CreateDailyRejMonitoring />,
  },
  {
    path: 'qualityDepartmentAbnSitCheckSheetList',
    element: <AbnormalSitChkShtList />,
  },
  {
    path: 'createQualityDepartmentAbnSitCheckSheet/:id?',
    element: <CreateAbnormalSitChkSht />,
  },
  {
    path: 'qualityDepartmentDailyBinCleaningCheckSheetList',
    element: <DailyBinCleaningChkShtList />,
  },
  {
    path: 'createQualityDepartmentDailyBinCleaningCheckSheet/:id?',
    element: <CreateDailyBinCleaningChkSht />,
  },
  {
    path: 'qualityDepartmentPokaYokeCheckSheetList',
    element: <PokaYokeChkShtList />,
  },
  {
    path: 'createQualityDepartmentPokaYokeCheckSheet',
    element: <CreatePokaYokeChkSht />,
  },
  {
    path: 'pallMachineCheckSheetList',
    element: <PallMachineCheckList />,
  },
  {
    path: 'pallMachineCheckListCreate',
    element: <PallMachineCheckListCreate />,
  },

  // part list
  {
    path: 'qualityDepartmentListOfParts',
    element: <PartListOfQtyDept />,
  },

  // spc for iai
  {
    path: 'qualityDept/spc/partlist',
    element: <PartListForSPC />,
  },
  {
    path: 'spcTiles/:partId',
    element: <SpcTiles />,
  },
  {
    path: 'newSpc/:partId',
    element: <SpcList />,
  },
  {
    path: 'createEditSpc/:partId/:id?',
    element: <CreateEditSpc />,
  },
  {
    path: 'spcFromInspection/:partId',
    element: <UnderConstructionPage />,
  },
  // end of spc

  // titration check sheet
  {
    path: 'qualitydepartment/titrationCheckSheet',
    element: <TitrationChkShtList />,
  },
  {
    path: 'qualitydepartment/createTitrationCheckSheet/:id?',
    element: <CreateTitrationChkSht />,
  },
  // PPE check sheet
  {
    path: 'qualitydepartment/PPECheckSheet',
    element: <PPEChkShtList />,
  },
  {
    path: 'qualitydepartment/createPPECheckSheet/:id?',
    element: <CreatePPEChkSht />,
  },

  // leica room check sheet
  {
    path: 'qualitydepartment/leicaRoomCheckSheet',
    element: <LeicaRoomList />,
  },
  {
    path: 'qualitydepartment/createLeicaRoomCheckSheet/:id?',
    element: <CreateLeicaRoom />,
  },
  // Diesel Monitoring Sheet
  {
    path: 'dieselMonitoringSheetList',
    element: <DieselMonitoringSheetList />,
  },
  {
    path: 'createEditDieselMonitoringSheet/:id?',
    element: <DieselMonitoringSheetCreate />,
  },
  {
    path: 'qualityDefects',
    element: <QualityDefects />,
  },
  {
    path: 'amgRejectionNote',
    element: <AmgRejectionNote />,
  },
];
// pdi department
const pdiDepartment = [
  {
    path: 'pdiDepartment',
    element: <PdiDepartmentHome />,
  },

  {
    path: 'PdiStandardList',
    element: <PDIStandardList />,
  },
  {
    path: 'createPdiStandartd/:moduleId',
    element: <CreatePDIStandard />,
  },
];

// dock audit plan

const dockAudit = [
  {
    path: 'dockAuditPlanTiles',
    element: <DockAuditPlanTiles />,
  },
  {
    path: 'dockAuditPlanPlanList',
    element: <DockAuditPlanPlanList />,
  },
  {
    path: 'dockAuditPlanPlanCreate/:id',
    element: <DockAuditPlanPlanCreate />,
  },
  {
    path: 'dockAuditPlanChecklistList',
    element: <DockAuditPlanChecklistList />,
  },
  {
    path: 'dockAuditPlanChecklistCreate/:moduleId?',
    element: <DockAuditPlanChecklistCreate />,
  },
];
// clause 1
const clause1 = [
  {
    path: 'clause1',
    element: <Clause1 />,
  },
  {
    path: 'npdMatrixList',
    element: <MatrixList />,
  },
  {
    path: 'createNpdMatrix/:projectId',
    element: <CreateMatrix />,
  },
  {
    path: 'npdTimePlanList',
    element: <TimePlanList />,
  },
  {
    path: 'createNpdTimePlan/:projectId',
    element: <CreateNpdTimePlan />,
  },
  {
    path: 'npdPartList',
    element: <NpdPartList />,
  },
  {
    path: 'masterListOfProject',
    element: <MasterListOfProject />,
  },
  {
    path: 'lessonLearn/list',
    element: <LessonLearnList />,
  },
];

// clause 5
const clause5 = [
  {
    path: 'clause5',
    element: <Clause5 />,
  },
  // job desc module
  {
    path: 'jobDescHome',
    element: <JobDescHome />,
  },

  {
    path: 'designation',
    element: <DesignationList />,
  },

  {
    path: 'JdList',
    element: <JDList />,
  },
  {
    path: 'JdCreate/:moduleId?',
    element: <JDCreate />,
  },
  {
    path: 'competencyList',
    element: <CompetencyMatrixList />,
  },
  {
    path: 'competencyCreate/:moduleId?',
    element: <CompetencyMatrixCreate />,
  },
  // end of job desc module
  {
    path: 'employeeList',
    element: <EmployeeList />,
  },
  {
    path: 'competenceMatrix/:id',
    element: <CompetenceMatrixEmployee />,
  },
  {
    path: 'employeeJD/:moduleId?',
    element: <EmployeeJD />,
  },
  {
    path: 'departmentList',
    element: <DepartmentList />,
  },
  {
    path: 'masterlistoffaculty',
    element: <FacultyList />,
  },
  //tni
  {
    path: 'trainingTniHome',
    element: <TrainingTniHome />,
  },
  {
    path: 'tniTiles',
    element: <TNITiles />,
  },
  {
    path: 'topicPaper/list',
    element: <TniTopicList />,
  },
  {
    path: 'createEditTopicPaper/:id?',
    element: <CreateEditTopicPaper />,
  },
  {
    path: 'quick_view_topic_paper/:id',
    element: <QuickViewTopicPaper />,
  },
  {
    path: 'masterlistofTniCategory',
    element: <MaterListTniCategory />,
  },
  {
    path: 'trainingCalenderTiles',
    element: <TrainingCalenderTiles />,
  },
  //tni end
  //training plan
  {
    path: 'tniTrainingPlanTiles',
    element: <TniTrainingPlanTiles />,
  },
  {
    path: 'tniTrainingPlanList',
    element: <TniTrainingPlanList />,
  },
  {
    path: 'tniTrainingPlanCreate/:id',
    element: <TniTrainingPlanCreate />,
  },
  {
    path: 'tniTraningPlanChecklistList',
    element: <TniTrainingPlanChecklistList />,
  },
  //csr plan
  {
    path: 'tniCsrPlanTiles',
    element: <TniCsrPlanTiles />,
  },
  {
    path: 'tniCsrPlanList',
    element: <TniCsrPlanList />,
  },
  {
    path: 'tniCsrPlanCreate/:id',
    element: <TniCsrPlanCreate />,
  },
  {
    path: 'tniCsrPlanChecklistList',
    element: <TniCsrPlanChecklistList />,
  },
  //tni plan
  {
    path: 'tniPlanList',
    element: <TniPlanList />,
  },
  {
    path: 'tniPlanCreate/:id',
    element: <TniPlanCreate />,
  },
  //skill Evaluation
  {
    path: 'skillEvaluationHome',
    element: <SkillEvaluationHome />,
  },
  {
    path: 'skillEvaluationCategoryList',
    element: <MaterListSkillCategory />,
  },
  {
    path: 'createEditTopicSkillPaper/:categoryId?',
    element: <CreateEditTopicSkillPaper />,
  },
  {
    path: 'viewTopicSkillPaper/:categoryId',
    element: <ViewTopicSkillPaper />,
  },
  {
    path: 'skillMatrixList',
    element: <SkillMatrixList />,
  },
  {
    path: 'skillMatrixCreate/:id',
    element: <SkillMatrixCreate />,
  },
];

// clause 18
const clause18 = [
  {
    path: 'clause18',
    element: <Clause18 />,
  },
];

//Process Audit Plan
const processAuditPlan = [
  {
    path: 'masterListOfProcessAudit',
    element: <MasterListOfProcessAudit />,
  },
  {
    path: 'processAuditTiles',
    element: <ProcessAuditTiles />,
  },
  {
    path: 'processAuditPlanPlanList',
    element: <ProcessAuditPlanList />,
  },
  {
    path: 'processAuditPlanCreate/:id',
    element: <ProcessAuditPlanCreate />,
  },
  {
    path: 'processAuditPlanChecklistList',
    element: <ProcessAuditPlanChecklistList />,
  },
  {
    path: 'processAuditPlanChecklistCreate/:moduleId?/:checklistType?',
    element: <ProcessAuditPlanChecklistCreate />,
  },
];
// layered audit plan
const layeredAuditPlan = [
  {
    path: 'layeredAuditPlanTiles',
    element: <LayeredAuditPlanTiles />,
  },
  {
    path: 'layeredAuditPlanlist',
    element: <LayeredAuditPlanList />,
  },
  {
    path: 'layeredAuditPlanCreate/:id',
    element: <LayeredAuditPlanCreate />,
  },

  {
    path: 'layeredAuditPlanChecklist',
    element: <LayeredAuditPlanChecklistList />,
  },
  {
    path: 'layeredAuditPlanChecklistCreate/:moduleId?',
    element: <LayeredAuditPlanChecklistCreate />,
  },
];

//millipore run chart
const milliPoreRunChart = [
  {
    path: 'milliporeRunChart',
    element: <MilliPoreRunChart />,
  },
];
const calibrationTeam = [
  {
    path: 'calibrationTeam',
    element: <CalibrationTeamHome />,
  },
  // Fixtures Validation Plan
  {
    path: 'FixturesPlanTiles',
    element: <FixturesPlanTiles />,
  },
  {
    path: 'FixturesPlanList',
    element: <FixturesPlanList />,
  },
  {
    path: 'FixturesPlanCreate/:id',
    element: <FixturesPlanCreate />,
  },
  {
    path: 'FixturesPlanChecklistList',
    element: <FixturesPlanChecklistList />,
  },
  // OLD FVP PLAN
  {
    path: 'OldFvpPlanTiles',
    element: <OldFvpPlanTiles />,
  },
  {
    path: 'OldFvpPlanPlanList',
    element: <OldFvpPlanList />,
  },
  {
    path: 'OldFvpPlanCreate/:id',
    element: <OldFvpPlanCreate />,
  },
  {
    path: 'OldFvpPlanChecklistList',
    element: <OldFvpPlanChecklistList />,
  },
  {
    path: 'MasterListofOldFvpPlan',
    element: <MasterListofOldFvpPlan />,
  },

  // PART LAYOUT Plan
  {
    path: 'PartLayoutPlanTiles',
    element: <PartLayoutPlanTiles />,
  },
  {
    path: 'PartLayoutPlanList',
    element: <PartLayoutPlanList />,
  },
  {
    path: 'PartLayoutPlanCreate/:id',
    element: <PartLayoutPlanCreate />,
  },
  {
    path: 'PartLayoutPlanChecklistList',
    element: <PartLayoutPlanChecklistList />,
  },

  // Gauges MSA Plan
  {
    path: 'gaugeMsaPlanTiles',
    element: <GaugeMsaPlanTiles />,
  },
  {
    path: 'gaugeMsaPlanList',
    element: <GaugeMsaPlanList />,
  },
  {
    path: 'gaugeMsaPlanCreate/:id',
    element: <GaugeMsaPlanCreate />,
  },
  {
    path: 'gaugeMsaPlanChecklist',
    element: <GaugeMsaPlanChecklistList />,
  },
  {
    path: 'gaugeMsaMasterList',
    element: <MasterListofGaugeMsaPlan />,
  },

  // Instrument MSA Plan
  {
    path: 'instrumentMsaPlanTiles',
    element: <InstrumentMsaPlanTiles />,
  },
  {
    path: 'instrumentMsaPlanList',
    element: <InstrumentMsaPlanList />,
  },
  {
    path: 'instrumentMsaPlanCreate/:id',
    element: <InstrumentMsaPlanCreate />,
  },
  {
    path: 'instrumentMsaPlanChecklist',
    element: <InstrumentMsaPlanChecklistList />,
  },
  {
    path: 'instrumentMsaMasterList',
    element: <MasterListofInstrumentMsaPlan />,
  },
  // UPLOAD
  {
    path: 'scopeCalibrationAgencyUpload',
    element: <ScopeCalibrationAgencyUpload />,
  },
  {
    path: 'instrumentCalibrationTiles',
    element: <InstrumentCalibrationTiles />,
  },
  {
    path: 'instrumentCalibrationPlanList',
    element: <InstrumentCalibrationPlanList />,
  },
  {
    path: 'instrumentCalibrationPlanCreate/:id',
    element: <InstrumentCalibrationPlanCreate />,
  },
  {
    path: 'instrumentCalibrationChecklistList',
    element: <InstrumentCalibrationChecklistList />,
  },
];

const riDepartment = [
  {
    path: 'supplierOverallRatingList',
    element: <SupplierOverallRatingList />,
  },
  {
    path: 'createEditSupplierOverallRating/:id?',
    element: <SupplierOverallRatingCreate />,
  },
  // Supplier SPC Plan
  {
    path: 'supplierSpcPlanTiles',
    element: <SupplierSpcPlanTiles />,
  },
  {
    path: 'supplierSpcPlanList',
    element: <SupplierSpcPlanList />,
  },
  {
    path: 'supplierSpcPlanCreate/:id',
    element: <SupplierSpcPlanCreate />,
  },
  {
    path: 'supplierSpcPlanChecklistList',
    element: <SupplierSpcPlanChecklistList />,
  },
  // Supplier Layout Plan
  {
    path: 'supplierLayoutPlanTiles',
    element: <SupplierLayoutPlanTiles />,
  },
  {
    path: 'supplierLayoutPlanList',
    element: <SupplierLayoutPlanList />,
  },
  {
    path: 'supplierLayoutPlanCreate/:id',
    element: <SupplierLayoutPlanCreate />,
  },
  {
    path: 'supplierLayoutPlanChecklistList',
    element: <SupplierLayoutPlanChecklistList />,
  },
  // supplier PPM
  {
    path: 'supplierPpmList',
    element: <SupplierPpmList />,
  },
  {
    path: 'supplierPpmCreate/:id?',
    element: <SupplierPpmCreate />,
  },
  {
    path: 'supplierPpm/dashboard',
    element: <SupplierPpmDashboardTabs />,
  },
  {
    path: 'luxLevelObsSheetList',
    element: <LuxLevelObsSheetList />,
  },
  {
    path: 'createEditLuxLevelObsSheet/:id?',
    element: <CreateLuxLevelObsSheet />,
  },
  // material Test Plan - Hose
  {
    path: 'materialTestPlanHoseTiles',
    element: <MaterialTestPlanHoseTiles />,
  },
  {
    path: 'materialTestPlanHoseList',
    element: <MaterialTestPlanHoseList />,
  },
  {
    path: 'materialTestPlanHoseCreate/:id',
    element: <MaterialTestPlanHoseCreate />,
  },
  {
    path: 'materialTestPlanHoseChecklistList',
    element: <MaterialTestPlanHoseChecklistList />,
  },
  // material Test Plan - O-ring & Grommet
  {
    path: 'materialTestPlanOringAndGrommetTiles',
    element: <MaterialTestPlanOringAndGrommetTiles />,
  },
  {
    path: 'materialTestPlanOringAndGrommetList',
    element: <MaterialTestPlanOringAndGrommetList />,
  },
  {
    path: 'materialTestPlanOringAndGrommetCreate/:id',
    element: <MaterialTestPlanOringAndGrommetCreate />,
  },
  {
    path: 'materialTestPlanOringAndGrommetChecklistList',
    element: <MaterialTestPlanOringAndGrommetChecklistList />,
  },
  // material Test Plan - Other
  {
    path: 'materialTestPlanOtherTiles',
    element: <MaterialTestPlanOtherTiles />,
  },
  {
    path: 'materialTestPlanOtherList',
    element: <MaterialTestPlanOtherList />,
  },
  {
    path: 'materialTestPlanOtherCreate/:id',
    element: <MaterialTestPlanOtherCreate />,
  },
  {
    path: 'materialTestPlanOtherChecklistList',
    element: <MaterialTestPlanOtherChecklistList />,
  },
];

const pipeModule = [
  {
    path: 'home/pipe',
    element: <PipeHome />,
  },
  {
    path: 'pipe',
    element: <PipeList />,
  },
  {
    path: 'pipeInspectionStdList',
    element: <PipeInspectionStandardList />,
  },
  {
    path: 'createPipeInspectionStd/:inspectionStandardId',
    element: <CreatePipeInpsectionStandard />,
  },
  {
    path: 'pipeInspectionReportList',
    element: <PipeInspectionReportList />,
  },
  {
    path: 'pipeInspectionReport/:partId/:inspectionReportId?',
    element: <PipeInspectionReport />,
  },
];
const sstPlatingModule = [
  {
    path: 'home/sst/plating',
    element: <SSTPlatingHome />,
  },
  {
    path: 'sst/plating',
    element: <SSTPlatingList />,
  },
  {
    path: 'sst/plating/inspection/standard/list',
    element: <SSTPlatingInspectionStandardList />,
  },
  {
    path: 'sst/plating/inspection/standard/create/:id',
    element: <CreateSSTPlatingInpsectionStandard />,
  },
  {
    path: 'sst/plating/inspection/report/list',
    element: <SSTPlatingInspectionReportList />,
  },
  {
    path: 'sst/plating/inspection/report/create/:partId/:id?',
    element: <SSTPlatingInspectionReport />,
  },
];

const Clause4 = [
  {
    path: 'listOfProcedures',
    element: <ListOfProcedures />,
  },
  {
    path: 'contingencyPlan',
    element: <ContingencyPlan />,
  },
];

const clipOutsertPlan = [
  {
    path: 'customerSatisfactionSurveyPlanTiles',
    element: <CustomerSatisfactionSurveyPlanTiles />,
  },
  {
    path: 'customerSatisfactionSurveyPlanList',
    element: <CustomerSatisfactionSurveyPlanList />,
  },
  {
    path: 'customerSatisfactionSurveyPlanCreate/:id',
    element: <CustomerSatisfactionSurveyPlanCreate />,
  },
  {
    path: 'customerSatisfactionSurveyPlanChecklistList',
    element: <CustomerSatisfactionSurveyPlanChecklistList />,
  },
  {
    path: 'CustomerSatisfactionSurveyPlanChecklistCreate/:moduleId?',
    element: <CustomerSatisfactionSurveyPlanChecklistCreate />,
  },
];

const forMSummary = [
  {
    path: 'create4mSummary',
    element: <CreateForMSummary />,
  },
  {
    path: '4mSummaryList',
    element: <SummaryTableList />,
  },
];

export const IndexCommon: routes[] = [
  {
    path: 'clauseUploadList',
    element: <ClauseUploadList />,
  },
  {
    path: 'listOfSlideShow',
    element: <ListOfSlideShow />,
  },
  {
    path: 'slideShow/:id',
    element: <SlideShow />,
  },
  {
    path: 'drawingManagement/:partId?',
    element: <DrawingnManagement />,
  },
  {
    path: 'riDepartment',
    element: <RIDeparmentHome />,
  },
  {
    path: 'masterListOfVendor',
    element: <VendirList />,
  },
  {
    path: 'supplierComplaintRegister',
    element: <SupplierComplaintList />,
  },
  {
    path: 'riPart',
    element: <RIPart />,
  },
  {
    path: 'riDocumentUpload',
    element: <RiDocumentUpload />,
  },
  {
    path: 'qualityDepartmentUpload',
    element: <QualityDepartmentUpload />,
  },
  {
    path: 'masterlistofTools',
    element: <ToolsList />,
  },
  {
    path: 'masterListOfCriticalSparePart',
    element: <CriticalSparePartsList />,
  },
  {
    path: 'maintenanceLog',
    element: <MaintenanceLogTiles />,
  },
  {
    path: 'breakDownLogBook',
    element: <BreakDownFinalList />,
  },
  {
    path: 'mtbf',
    element: <MtbfList />,
  },
  {
    path: 'mtbfCreate/:id',
    element: <MtbfCreate />,
  },
  {
    path: 'mttr',
    element: <MttrList />,
  },
  {
    path: 'mttrCreate/:id',
    element: <MttrCreate />,
  },
  {
    path: 'toolsHistory/:id',
    element: <ToolsHistory />,
  },
  {
    path: 'milliporeList',
    element: <MilliporeList />,
  },
  {
    path: 'milliporeCreate/:partId/:pdirId?',
    element: <MilliporeCreate />,
  },
  {
    path: 'airbornList',
    element: <AirbornList />,
  },
  {
    path: 'airbornCreate/:pdirId?',
    element: <AirbornCreate />,
  },
  {
    path: 'instrumentTiles',
    element: <InstrumentTiles />,
  },
  {
    path: 'instrumentplanlist',
    element: <PlanList />,
  },
  {
    path: 'instrumentplancreate/:id',
    element: <PlanCreate />,
  },
  {
    path: 'instrumentMasterlist',
    element: <InstrumentMasterList />,
  },
  {
    path: 'checklistList',
    element: <ChecklistList />,
  },
  // ----end ----

  //RAW MATERIAL TEST PLAN
  {
    path: 'RawMaterialPlanTiles',
    element: <RawMaterialPlanTiles />,
  },
  {
    path: 'RawMaterialPlanPlanList',
    element: <RawMaterialPlanPlanList />,
  },
  {
    path: 'RawMaterialPlanPlanCreate/:id',
    element: <RawMaterialPlanPlanCreate />,
  },
  // ---- end ---

  // RAW MATERIAL TEST PLAN (RUBBER & O'RING  PARTS)
  {
    path: 'RubberoringPartsTiles',
    element: <RubberoringPartsTiles />,
  },
  {
    path: 'RubberoringPartsPlanList',
    element: <RubberoringPartsPlanList />,
  },
  {
    path: 'RubberoringPartsPlanCreate/:id',
    element: <RubberoringPartsPlanCreate />,
  },
  {
    path: 'RubberoringPartsChecklistList',
    element: <RubberoringPartsChecklistList />,
  },
  {
    path: 'MasterListofRubberoringParts',
    element: <MasterListofRubberoringParts />,
  },
  // ----end ----

  // RAW MATERIAL TEST PLAN - BOP
  {
    path: 'BOPPlanTiles',
    element: <BOPPlanTiles />,
  },
  {
    path: 'BOPPlanPlanList',
    element: <BOPPlanPlanList />,
  },
  {
    path: 'BOPPlanPlanCreate/:id',
    element: <BOPPlanPlanCreate />,
  },
  {
    path: 'BOPPlanChecklistList',
    element: <BOPPlanChecklistList />,
  },
  {
    path: 'MasterListofBOPPlan',
    element: <MasterListofBOPPlan />,
  },
  // ----end ----

  // PLAN FOR SST
  {
    path: 'SSTPlanTiles',
    element: <SSTPlanTiles />,
  },
  {
    path: 'SSTPlanPlanList',
    element: <SSTPlanPlanList />,
  },
  {
    path: 'SSTPlanPlanCreate/:id',
    element: <SSTPlanPlanCreate />,
  },
  {
    path: 'SSTPlanChecklistList',
    element: <SSTPlanChecklistList />,
  },
  {
    path: 'MasterListofSSTPlan',
    element: <MasterListofSSTPlan />,
  },
  // ----end ----
  // PLAN FOR Tubes
  {
    path: 'TubesTiles',
    element: <TubesTiles />,
  },
  {
    path: 'TubesPlanList',
    element: <TubesPlanList />,
  },
  {
    path: 'TubesPlanCreate/:id',
    element: <TubesPlanCreate />,
  },
  {
    path: 'TubesChecklistList',
    element: <TubesChecklistList />,
  },
  {
    path: 'MasterListofTubes',
    element: <MasterListofTubes />,
  },
  // ----end ----

  // LAYOUT PLAN
  // {
  //   path: 'LayoutPlanTiles',
  //   element: <LayoutPlanTiles />,
  // },
  // {
  //   path: 'layoutplanlist',
  //   element: <LayoutPlanList />,
  // },
  // {
  //   path: 'layoutplancreate/:id',
  //   element: <LayoutPlanCreate />,
  // },
  // {
  //   path: 'layoutchecklist',
  //   element: <LayoutChecklistList />,
  // },

  // Attribute Gauges PLAN
  {
    path: 'attributeGaugesTiles',
    element: <AttributeGaugesTiles />,
  },
  {
    path: 'attributeGaugeslist',
    element: <AttributeGaugesPlanList />,
  },
  {
    path: 'attributeGaugescreate/:id',
    element: <AttributeGaugesPlanCreate />,
  },
  {
    path: 'attributeGaugesChecklist',
    element: <AttributeGaugesChecklistList />,
  },
  {
    path: 'attributeGaugesMasterlist',
    element: <MasterListofGauges />,
  },

  // Appearance Plan
  {
    path: 'appearanceplanTiles',
    element: <AppearancePlanTiles />,
  },
  {
    path: 'appearanceplanlist',
    element: <AppearancePlanPlanList />,
  },
  {
    path: 'appearanceplancreate/:id',
    element: <AppearancePlanPlanCreate />,
  },
  {
    path: 'appearanceplanChecklist',
    element: <AppearancePlanChecklistList />,
  },
  {
    path: 'appearanceplanMasterlist',
    element: <MasterListofAppearancePlan />,
  },
  // KRA DASHBOARD
  {
    path: 'kraTiles',
    element: <KRATiles />,
  },
  {
    path: 'kraList',
    element: <KRAList />,
  },
  {
    path: 'kraCreate/:id',
    element: <KRACreate />,
  },
  //Mushin Help Desk
  {
    path: 'mushinHelpDesk',
    element: <MushinHelpDesk />,
  },

  // first aid box
  {
    path: 'firstAidBox',
    element: <FirstAidBoxList />,
  },
  // LIST OF FIRE EXTINGUISHER
  {
    path: 'listOfFireExtinguisher',
    element: <ListOfFireExtinguisher />,
  },

  {
    path: 'safetyCommittee',
    element: <SafetyCommittee />,
  },
  {
    path: 'management5S',
    element: <Management5S />,
  },

  // product plan
  {
    path: 'productAuditPlanTiles',
    element: <ProductAuditPlanTiles />,
  },
  {
    path: 'productAuditPlanPlanList',
    element: <ProductAuditPlanPlanList />,
  },
  {
    path: 'productAuditPlanPlanCreate/:id',
    element: <ProductAuditPlanPlanCreate />,
  },
  {
    path: 'masterListofProductAuditPlan',
    element: <MasterListofProductAuditPlan />,
  },
  {
    path: 'productAuditPlanChecklistList',
    element: <ProductAuditPlanChecklistList />,
  },
  {
    path: 'productAuditPlanChecklistCreate/:moduleId?',
    element: <ProductAuditPlanChecklistCreate />,
  },
  {
    path: 'notificationList',
    element: <NotificationList />,
  },
  {
    path: 'waterChangeFrequency',
    element: <WaterChangeFrequency />,
  },
  {
    path: 'ultraSonicWaterChangeCheckSheet',
    element: <UltraSonicWaterChangeCheckSheet />,
  },
  {
    path: 'flushingOilChangeCheckSheet',
    element: <FlushingOilChangeCheckSheet />,
  },
  {
    path: 'airdecayChecksheet',
    element: <AirDecayChecksheet />,
  },
  {
    path: 'airUnderWaterChecksheet',
    element: <AirUnderWaterChecksheet />,
  },
  {
    path: 'leicaHome',
    element: <LeicaHome />,
  },
  {
    path: 'kaizenSubmissionList',
    element: <KaizenSubmissionList />,
  },

  {
    path: 'pastTroubleDatabaseList',
    element: <PastTroubleDatabaseList />,
  },
  {
    path: 'documentPendingForApproval',
    element: <ApprovalList />,
  },
  {
    path: 'cmmLogBookList',
    element: <CmmLogBookList />,
  },
  {
    path: 'qualitydepartment/amgRejectionNoteDashboard',
    element: <AmgRejectionNoteDashboardTabs />,
  },
  ...qualityDepartment,
  ...pfmeaManagement,
  ...storeManagement,
  ...customerStandardModule,
  ...inspectionStandard,
  ...inspectionReportRoute,
  ...productionLogBook,
  ...supplierDocument,
  ...rejectionNoteRoute,
  ...pdiDepartment,
  ...dockAudit,
  ...clause5,
  ...clause1,
  ...processAuditPlan,
  ...layeredAuditPlan,
  ...supplierAuditPlan,
  ...milliPoreRunChart,
  ...calibrationTeam,
  ...riDepartment,
  ...pipeModule,
  ...sstPlatingModule,
  ...Clause4,
  ...clause8,
  ...clause6,
  ...clipOutsertPlan,
  ...productionDept,
  ...reworkRegisterRoute,
  ...clause18,
  ...forMSummary,
];
