import { GrUser, GrVmMaintenance } from 'react-icons/gr';
import { HiDocumentReport } from 'react-icons/hi';
import { TbReportAnalytics } from 'react-icons/tb';
import { GiRing } from 'react-icons/gi';
import { SiMaterialdesignicons } from 'react-icons/si';
import { GiUnbalanced } from 'react-icons/gi';
import { GiTeePipe } from 'react-icons/gi';
import { TbReport } from 'react-icons/tb';
import { GrServices } from 'react-icons/gr';
import { TiFlowParallel } from 'react-icons/ti';
import { RenderTiles } from '../../components/common';
import ChildHeader from '../../components/ui/ChildHeader';
import { FcEngineering, FcRating } from 'react-icons/fc';
import { AiFillLayout, AiTwotoneExperiment } from 'react-icons/ai';
import { GiLightningSlashes } from 'react-icons/gi';
import { BsMotherboard } from 'react-icons/bs';
import { FaBusinessTime } from 'react-icons/fa';

const tiles = [
  {
    sNo: 1,
    link: '/common/riPart',
    isLink: true,
    icon: <HiDocumentReport size="5rem" color="#003566" />,
    p: 'RI PART',
    span: 'LIST',
    isIcon: true,
  },
  {
    sNo: 4,
    link: `/common/inspectionStandardList`,
    isLink: true,
    icon: <TbReport size="5rem" color="#a12222" />,
    p: 'INSPECTION',
    span: 'STANDARD',
    isIcon: true,
  },
  {
    sNo: 4,
    link: `/common/vendorDocumentList`,
    isLink: true,
    icon: <TbReport size="5rem" color="#a12222" />,
    p: 'SUPPLIER',
    span: 'DOCUMENTS',
    isIcon: true,
  },
  {
    sNo: 4,
    link: `/common/vendorDocumentListNew`,
    isLink: true,
    icon: <TbReport size="5rem" color="#a12222" />,
    p: 'SUPPLIER',
    span: 'DOCUMENTS NEW',
    isIcon: true,
  },

  {
    sNo: 3,
    link: '/common/masterListOfVendor',
    isLink: true,
    icon: <TbReportAnalytics size="5rem" color="#064a29" />,
    p: 'MASTER LIST OF',
    span: 'VENDOR',
    isIcon: true,
  },
  {
    sNo: 5,
    link: `/common/inspectionReportList`,
    isLink: true,
    icon: <TbReportAnalytics size="5rem" color="#a12222" />,
    p: 'INCOMING INSPECTION',
    span: 'Report',
    isIcon: true,
  },
  {
    sNo: 6,
    link: `/common/supplierComplaintRegister`,
    isLink: true,
    icon: <GrUser size="5rem" color="#003566" />,
    p: 'SUPPLIER COMPLAINT',
    span: 'REGISTER',
    isIcon: true,
  },
  // {
  //   sNo: 7,
  //   link: `/common/riDocumentUpload/?documentType=iaiSSTPlan`,
  //   isLink: true,
  //   icon: <HiDocumentReport size="5rem" color="#003566" />,
  //   p: 'IAI SST',
  //   span: 'PLAN',
  //   isIcon: true,
  // },
  // {
  //   sNo: 8,
  //   link: `/common/riDocumentUpload/?documentType=iaiMtcPlanList`,
  //   isLink: true,
  //   icon: <HiDocumentReport size="5rem" color="#003566" />,
  //   p: 'IAI MTC',
  //   span: 'PLAN',
  //   isIcon: true,
  // },
  {
    sNo: 9,
    link: `/common/riDocumentUpload/?documentType=riDocuments`,
    isLink: true,
    icon: <HiDocumentReport size="5rem" color="#003566" />,
    p: 'RI',
    span: 'DOCUMENTS',
    isIcon: true,
  },
  // {
  //   sNo: 10,
  //   link: `/common/RawMaterialPlanTiles`,
  //   isLink: true,
  //   icon: <SiMaterialdesignicons size="4rem" color="#343a40" />,
  //   p: 'RAW MATERIAL',
  //   span: 'TEST PLAN (Child Parts)',
  //   isIcon: true,
  // },
  // {
  //   sNo: 10,
  //   link: `/common/RubberoringPartsTiles`,
  //   isLink: true,
  //   icon: <GiRing size="5rem" color="#343a40" />,
  //   p: 'RAW MATERIAL',
  //   span: "TEST PLAN (RUBBER & O'RING  PARTS)",
  //   isIcon: true,
  // },
  {
    sNo: 10,
    link: `/common/SSTPlanTiles`,
    isLink: true,
    icon: <GrServices size="5rem" color="#343a40" />,
    p: 'SST',
    span: 'PLAN',
    isIcon: true,
  },
  {
    sNo: 10,
    link: `/common/TubesTiles`,
    isLink: true,
    icon: <GiTeePipe size="5rem" color="#343a40" />,
    p: 'Tube',
    span: 'PLAN',
    isIcon: true,
  },
  // {
  //   sNo: 10,
  //   link: `/common/BOPPlanTiles`,
  //   isLink: true,
  //   icon: <GiUnbalanced size="5rem" color="#343a40" />,
  //   p: 'BOP',
  //   span: 'PLAN',
  //   isIcon: true,
  // },
  {
    sNo: 20,
    link: '/common/supplierAuditTiles',
    isLink: true,
    clauseNo: 4,
    icon: <GrVmMaintenance size="5rem" color="#495057" />,
    p: 'Supplier Audit',
    span: 'Plan',
    isIcon: true,
  },
  {
    sNo: 1,
    link: '/common/supplierOverallRatingList',
    isLink: true,
    icon: <FcRating size="5rem" color="#1864ab" />,
    p: 'SUPPLIER OVERALL',
    span: 'RATING',
    isIcon: true,
  },
  {
    sNo: 21,
    link: '/common/home/pipe',
    isLink: true,
    clauseNo: 4,
    icon: <TiFlowParallel size="5rem" color="#003566" />,
    p: 'Pipe',
    span: '',
    isIcon: true,
  },
  {
    sNo: 22,
    link: '/common/supplierSpcPlanTiles?filter=createdAt',
    isLink: true,
    clauseNo: 4,
    icon: <AiFillLayout size="5rem" color="#1864ab" />,
    p: 'Supplier SPC',
    span: 'Plan',
    isIcon: true,
  },
  {
    sNo: 23,
    link: '/common/supplierLayoutPlanTiles?filter=createdAt',
    isLink: true,
    clauseNo: 4,
    icon: <AiFillLayout size="5rem" color="#1864ab" />,
    p: 'Supplier Layout',
    span: 'Plan',
    isIcon: true,
  },
  {
    sNo: 24,
    link: '/common/supplierPpmList',
    isLink: true,
    clauseNo: 4,
    icon: <AiFillLayout size="5rem" color="#1864ab" />,
    p: 'Supplier',
    span: 'PPM',
    isIcon: true,
  },
  {
    sNo: 24,
    link: '/common/supplierPpm/dashboard',
    isLink: true,
    clauseNo: 4,
    icon: <AiFillLayout size="5rem" color="#1864ab" />,
    p: 'Supplier PPM',
    span: 'Dashboard',
    isIcon: true,
  },
  {
    sNo: 24,
    link: '/common/luxLevelObsSheetList',
    isLink: true,
    clauseNo: 4,
    icon: <GiLightningSlashes size="5rem" color="#1864ab" />,
    p: 'LUX LEVEL',
    span: 'OBSERVATION SHEET',
    isIcon: true,
  },
  {
    sNo: 25,
    link: `/common/materialTestPlanHoseTiles`,
    isLink: true,
    icon: <AiTwotoneExperiment size="5rem" color="purple" />,
    p: 'Material Testing Plan',
    span: 'HOSE',
    isIcon: true,
  },
  {
    sNo: 26,
    link: `/common/materialTestPlanOringAndGrommetTiles`,
    isLink: true,
    icon: <FcEngineering size="5rem" color="purple" />,
    p: 'Material Testing Plan',
    span: 'O-ring & Grommet',
    isIcon: true,
  },
  {
    sNo: 27,
    link: `/common/materialTestPlanOtherTiles`,
    isLink: true,
    icon: <BsMotherboard size="5rem" color="blue" />,
    p: 'Material Testing Plan',
    span: 'BOP Child Parts',
    isIcon: true,
  },
  {
    sNo: 21,
    link: '/common/home/sst/plating',
    isLink: true,
    clauseNo: 4,
    icon: <FaBusinessTime size="5rem" color="#003566" />,
    p: 'PLATING',
    span: 'SST',
    isIcon: true,
  },
];

const RIDeparment = () => {
  return (
    <>
      <ChildHeader text="RI DEPARTMENT" />
      <RenderTiles height="100%" tilesObj={tiles} />
    </>
  );
};

export default RIDeparment;
